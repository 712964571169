import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerStoryPage from "../../components/molecules/InnerStoryPage"
import { stories } from "../../content/Stories"

const slug = `ndis-home-in-northern-adelaide`;

const storyPost = stories.find(post => post.slug === slug) // Find the blog post by slug

const NdisHomeInNorthernAdelaide = () => (
  <Layout>
    <Seo title="Ndis Home In Northern Adelaide" />

    <InnerStoryPage post={storyPost} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default NdisHomeInNorthernAdelaide
