import React from "react"
import Button from "../atoms/Button"
import Dropdown from "../atoms/Dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faLeaf,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import ImageRenderer from "../atoms/ImageRenderer"

const ContactForm = ({
  labelEC,
  fieldEC,
  dropDownbtnEC,
  dropDownIconEC,
  buttonText,
  buttonEC,
}) => {
  const dropdownOptions = [
    "Please Select",
    "General Query",
    "Request a Demo",
    "Support Team",
  ]
  return (
    <div
      className="relative rounded-[32px] px-5 md:px-10 py-10 bg-white placeholder-gray"
      style={{ boxShadow: `0px 0px 15px #00000025` }}
    >
      <div className="absolute w-full h-full right-[-20px] bottom-[-20px] rounded-[32px] bg-secondary z-[-1]"></div>
      <div className="absolute right-[-40px] lg:right-[-40px] top-[-45px] lg:top-[-45px] contact--form-design-image-top-left">
        <ImageRenderer img={`contact--form-design-image-top-left`} alt={`contact--form-design-image-top-left`} />
      </div>
      <div className="absolute left-[-20px] lg:left-[-70px] bottom-[-80px] z-[-10]">
        <ImageRenderer img={`contact--form-design-image-bottom`} alt={`contact--form-design-image-bottom`} />
      </div>
      <form>
        <h2 className="text-[#25282C] text-[24px] md:text-[32px] font-[600] laeading-[1.4em]">
          SEND US A MESSAGE
        </h2>
        <div className="flex flex-col md:flex-row gap-[20px] md:gap-[40px] mt-10 mb-4">
          <div className="mb-8 flex-[1]">
            <label
              htmlFor="full_name"
              className={`text-[#10242C] block text-[16px] font-[600] leading-[1.4em] mb-2 ${labelEC}`}
            >
              Full Name
            </label>
            <div className="relative">
              <input
                id="full_name"
                type="text"
                className={`border-b border-[#000000] outline-0 bg-transparent py-2 text-[15px] w-full text-[#10242C] ${fieldEC}`}
                placeholder="Type here"
              />
              <FontAwesomeIcon
                icon={faUser}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-[#10242C]`}
              />
            </div>
          </div>
          <div className="mb-8 flex-[1]">
            <label
              htmlFor="email"
              className={`text-[#10242C] block text-[16px] font-[600] leading-[1.4em] mb-2 ${labelEC}`}
            >
              Email
            </label>
            <div className="relative">
              <input
                id="email"
                type="email"
                className={`border-b border-[#000000] outline-0 bg-transparent py-2 text-[15px] w-full text-[#10242C] ${fieldEC}`}
                placeholder="Type here"
              />
              <FontAwesomeIcon
                icon={faEnvelope}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-[#10242C]`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-5 mb-4">
          <div className="mb-8 flex-[1]">
            <label
              htmlFor="category"
              className={`text-[#10242C] block text-[16px] font-[600] leading-[1.4em] mb-2 ${labelEC}`}
            >
              How did you hear about us?
            </label>
            <Dropdown
              id="category"
              options={dropdownOptions}
              dropDownbtnEC={dropDownbtnEC}
              dropDownIconEC={dropDownIconEC}
            />
          </div>
          <div className="mb-8 flex-[1]">
            <label
              htmlFor="phone"
              className={`text-[#10242C] block text-[16px] font-[600] leading-[1.4em] mb-2 ${labelEC}`}
            >
              Phone
            </label>
            <div className="relative">
              <input
                id="phone"
                type="text"
                className={`border-b border-[#000000] outline-0 bg-transparent py-2 text-[15px] w-full text-[#10242C] ${fieldEC}`}
                placeholder="Type here"
              />
              <FontAwesomeIcon
                icon={faPhone}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-[#10242C]`}
              />
            </div>
          </div>
        </div>
        <div className="mb-8">
          <label
            htmlFor="message"
            className={`text-[#10242C] block text-[16px] font-[600] leading-[1.4em] mb-3 ${labelEC}`}
          >
            Message
          </label>
          <div className="relative">
            <textarea
              id="message"
              className={`border-b border-[#000000] outline-0 bg-transparent py-2 text-[15px] w-full text-[#10242C] min-h-[120px] ${fieldEC}`}
              placeholder="Your message go here..."
            ></textarea>
            <FontAwesomeIcon
              icon={faLeaf}
              className={`absolute right-3 bottom-5 text-[#10242C]`}
            />
          </div>
        </div>
        <div className="text-center mt-10">
          <Button
            to={"/"}
            href={""}
            text={buttonText ? buttonText : "Send Message"}
            ec={`secondary-btn !px-8 ${buttonEC}`}
          />
        </div>
      </form>
    </div>
  )
}

export default ContactForm
