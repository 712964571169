import React from "react"
import ImageRenderer from "./ImageRenderer"

const SectionTitle = ({ componentEC, title, titleEC, isTopImage }) => {
  return (
    <div className={componentEC}>
      {isTopImage !== false ? (
        <div className="block">
          <ImageRenderer
            img={`section--title-image`}
            alt={`section--title-image`}
          />
        </div>
      ) : (
        ""
      )}

      <h2
        className={`heading-text-highligted mt-3 inline-flex flex-wrap items-center gap-[8px] text-[#25282C] text-[26px] font-[600] md:text-[40px] leading-[1.4em] ${titleEC}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  )
}

export default SectionTitle
