import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import ContentSection from "./ContentSection"

const InnerStoryPage = ({ post }) => {
  return (
    <>
      <div
        className="pt-16 pb-5"
        style={{
          backgroundImage: `linear-gradient(to bottom, #FAFDEE 60%, transparent 40%)`,
        }}
      >
        <div className="cc-section--horizontal-space">
          <div className="relative">
            <ImageRenderer
              img={post.video.path}
              alt={post.video.alt}
            />
            <div className="inner-blog--featuered-image-design-image-right-top max-w-[50px] md:max-w-[100%] absolute right-[-15px] md:right-[5px] lg:right-[-50px] top-[-20px] md:top-[-45px] lg:top-[-40px]">
              <ImageRenderer
                img={`contact--form-design-image-top-left`}
                alt={`contact--form-design-image-top-left`}
              />
            </div>
            <div className="inner-blog--featuered-image-design-image-left-bottom max-w-[200px] md:max-w-[100%] absolute left-[-15px] lg:left-[-70px] bottom-[-15px] md:bottom-[-80px] z-[-1]">
              <ImageRenderer
                img={`blog-inner--oval-shape`}
                alt={`blog-inner--oval-shape`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="cc-section--horizontal-space">
        <div className="mt-10 md:mt-32">
          <h1 className="text-[#25282C] text-[28px] md:text-[40px] leading-[1.4em] font-[600]">
            {post.title}
          </h1>
        </div>
        <div className="pt-0 md:pt-10 pb-5">
          {post.contentSections.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              titleEC={section.titleEC}
              description={section.description}
              list={section.list}
              image={section.image}
              imageEC={section.imageEC}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default InnerStoryPage
