export const stories = [
    {
        title: `NDIS Home, in Northern Adelaide`,
        slug: `ndis-home-in-northern-adelaide`,
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.`,
        date: `05 July 2024`,
        image: {
            path: `story-image`,
            alt: `story-image`
        },
        video: {
            path: `stories-page--featured-image`,
            text: `stories-page--featured-image`
        },
        contentSections: [
            {
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu,
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                `
            }
        ]
    },
    {
        title: `CareAssue NDIS Housing Achievement`,
        slug: `ndis-home-in-northern-adelaide`,
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.`,
        date: `05 July 2024`,
        image: {
            path: `story-image2`,
            alt: `story-image2`
        },
        video: {
            path: `stories-page--featured-image`,
            text: `stories-page--featured-image`
        },
        contentSections: [
            {
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu,
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                `
            }
        ]
    },
    {
        title: `Kalculators Satisfaction Level from Accountants`,
        slug: `ndis-home-in-northern-adelaide`,
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum congue dictum ac, interdum mattis.`,
        date: `05 July 2024`,
        image: {
            path: `blog--image`,
            alt: `blog--image`
        },
        video: {
            path: `stories-page--featured-image`,
            text: `stories-page--featured-image`
        },
        contentSections: [
            {
                description: `
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu,
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
                `
            }
        ]
    },
]